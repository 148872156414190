import { validateCPF } from "./validate-cpf";

export function formatCpf(cpf) {
  if(!cpf){
    return cpf
  }
    cpf = cpf?.replace(/[^\d]/g, "");
  let returnCpf = cpf;
  let isValid = false;
  if (!validateCPF(cpf) || cpf.length !== 11){
    isValid = false;
  } else {
    isValid = true;
  }
  // Remove caracteres especiais do CPF (pontos e hífens)
  cpf = cpf.replace(/[^\d]/g, "");

  // Formata o CPF com pontos e hífen
  returnCpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  return returnCpf;
}
