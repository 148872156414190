import React, { useState } from "react";
import "./listaPacientes.css";
import { IoMdAddCircle } from "react-icons/io";
import { FaPencilAlt, FaTrashAlt, FaRegEye, FaBarcode } from "react-icons/fa";

import { Link, useNavigate } from "react-router-dom"; // Importe Link ou NavLink em vez de redirect
import config from "../../config/variables.json";
import { toast } from "react-toastify";
// import { da } from "date-fns/locale";

const PacientesLista = ({ pacientes, user, getPacientes }) => {
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();

  const handleSort = (key) => {
    console.log(pacientes)
    if (sortBy === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };

  const sortedPacientes = pacientes.sort((a, b) => {
    if (sortBy && sortOrder === "asc") {
      if (sortBy === "endereco.cidade") {
        return a.endereco.cidade.localeCompare(b.endereco.cidade);
      } else {
        return a[sortBy].localeCompare(b[sortBy]);
      }
    } else if (sortBy && sortOrder === "desc") {
      if (sortBy === "endereco.cidade") {
        return b.endereco.cidade.localeCompare(a.endereco.cidade);
      } else {
        return b[sortBy].localeCompare(a[sortBy]);
      }
    }
    return 0;
  });
  
  const actionDelete = async (data) => {
    let { nome, cpf, _id } = data;
    const confirmarExclusao = window.confirm(`Tem certeza que deseja excluir o paciente?
    Dados do paciente:
      ${nome}
      CPF: ${cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
      
  Lembrando que, uma vez feita esta ação, não será possível reverter.
      `);

    if (_id && confirmarExclusao) {
      setLoading(true);
      const response = await fetch(
        `${config.urlServer}/cadastros/pacientes/delete/${_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
        }
      );
      if (!response.ok) {
        // Se a resposta não for bem-sucedida, lança um erro
        toast.error("Erro ao excluir paciente");
        throw new Error("Erro ao excluir paciente");
      }

      // Se chegou aqui, significa que a exclusão foi bem-sucedida
      const pacienteRemovido = await response.json();
      toast.success("Paciente excluído com sucesso!");
      setLoading(false);
      return getPacientes();
    }
  };

  return (
    <section className="content-lista-pacientes">
      <div>
        <h3>Pacientes da Clínica</h3>
        <Link to="/cadastros/pacientes/criar-novo">
          <button>
            <IoMdAddCircle /> Criar novo
          </button>
        </Link>{" "}
      </div>
      <table className="table-listagem-pacientes">
        <thead>
          <tr>
            {/* <th onClick={() => handleSort("avatar")}>Avatar</th> */}
            <th onClick={() => handleSort("nome")}>Nome</th>
            <th onClick={() => handleSort("nome_responsavel")}>Responsável</th>
            <th onClick={() => handleSort("cpf")}>CPF</th>
            <th onClick={() => handleSort("endereco.cidade")}>Cidade</th>
            <th onClick={() => handleSort("telefone_1")}>Telefone</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {sortedPacientes?.map((paciente, index) => (
            <tr key={paciente?._id}>
              {/* <td>{paciente.avatar}</td> */}
              <td>{paciente?.nome}</td>
              <td>{paciente?.nome_responsavel}</td>
              <td>
                {paciente?.cpf.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3-$4"
                )}
              </td>
              <td>{paciente?.endereco?.cidade}</td>
              <td>
                {paciente.telefone_1.replace(
                  /(\d{2})(\d{5})(\d{4})/,
                  "($1) $2-$3"
                )}
              </td>
              <td>
                {/* <button style={{ backgroundColor: "#d9e1e6" }}>
                  <FaRegEye />
                </button> */}
                {/* <Link to={`/cadastros/guias-operadoras/${paciente?._id}`}> */}
                  <button style={{ backgroundColor: "#b3bbc2" }}
                  onClick={()=>{navigate(`/cadastros/guias-operadoras/paciente/${paciente?._id}`, {state:{ paciente }})}}
                  >
                  Guias
                    {/* <FaBarcode /> */}
                  </button>
                {/* </Link> */}

                <Link to={`/cadastros/pacientes/editar/${paciente?._id}`}>
                  <button style={{ backgroundColor: "#b3bbc2" }}>
                  
                    <FaPencilAlt />
                  </button>
                </Link>

                <button
                  style={{ backgroundColor: "#f64846", color: "#fff" }}
                  onClick={() => actionDelete(paciente)}
                >
                  <FaTrashAlt />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default PacientesLista;
