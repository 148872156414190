import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { FiMessageSquare } from "react-icons/fi";
import Header from "../../components/Header";
import Title from "../../components/Title";
import FormularioCadastroPaciente from "../../components/FormularioCadastroPaciente";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/auth";

import config from "../../config/variables.json";

export default function EditarPaciente() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [paciente, setPaciente] = useState(null);

  useEffect(() => {
    console.log("1");
    getPaciente();
  }, [id]);

  const getPaciente = async () => {
    if (id) {
      setLoading(true);
      const response = await fetch(
        `${config.urlServer}/cadastros/pacientes/paciente/${id}`,
        {
          method: "GET", // Use GET para listar empresas
          headers: {
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
        }
      );

      if (!response.ok) {
        let { message } = await response.json();
        setLoading(false);

        throw new Error(`${message}`);
      }

      const data = await response.json();
      console.log(data);
      setPaciente(data);
      setLoading(false);
    }
  };
  const update_paciente = async (data) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/cadastros/pacientes/update/${id}`,

        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status !== 201) {
        const errorData = await response.json();
        const errorMessage =
          errorData.error || "Erro desconhecido ao criar paciente";
        toast.error(errorMessage);

        throw new Error(errorMessage);
      }

      const json = await response.json();

      setLoading(false);
      toast.success("Paciente criado com sucesso!");
    } catch (error) {}
  };

  return (
    <div style={{ display: "flex" }}>
      <Header />

      <div className="content">
        <Title name="Cadastros / Pacientes / Editar Paciente">
          <FiMessageSquare size={25} />
        </Title>

        <FormularioCadastroPaciente
          action={update_paciente}
          paciente={paciente}
        />
      </div>
    </div>
  );
}
