import { useState, useContext, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { FiMessageSquare } from "react-icons/fi";
import Header from "../../components/Header";
import Title from "../../components/Title";
import FormularioCadastroGuia from "../../components/FormularioCadastroGuia";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/auth";
import config from '../../config/variables.json';

export default function EditarGuiaOperadora() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [guia, setGuia] = useState(null);
  const location = useLocation();
  const { paciente } = location.state;

  useEffect(() => {
    // Aqui você pode buscar os dados da guia pelo ID
 

    fetchGuia();
  }, [id, user.email, user.token]);

  const fetchGuia = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/cadastros/guias-operadoras/paciente/guia/${id}`, // Rota para buscar guia pelo ID
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Erro desconhecido ao buscar guia";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      const guiaData = await response.json();
      setGuia(guiaData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const editarGuia = async (data) => {
    try {
      setLoading(true);

      console.log( user.email,user.token,)
      const response = await fetch(
        `${config.urlServer}/cadastros/guias-operadoras/paciente/guia/editar/${id}`, // Rota para editar guia pelo ID
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email, 
            token: user.token,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Erro desconhecido ao editar guia";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      setLoading(false);
      toast.success("Guia editada com sucesso!");
      fetchGuia()
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Header />
      <div className="content">
        <Title name="Cadastros / Guias Operadoras / Editar Guia">
          <FiMessageSquare size={25} />
        </Title>

          <FormularioCadastroGuia action={editarGuia} guia={guia} paciente={paciente} loading={loading}/>
      </div>
    </div>
  );
}
