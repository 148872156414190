// Lista de dados fictícios
const listaDadosFicticios = [
    {
      nome: "Fulano de Janeiro",
      cpf: "00123456789",
      carteira_unimed: "1122334455", 
      data_nascimento: "2001-01-01",
      sexo: "masculino",
      nome_responsavel: "O Responsa",
      telefone_1: "5499123456",
      telefone_2: "5499942355",
      observacoes: "Isso é um teste de cadastro",
      cep: "95330000",
      cidade: "Veranópolis",
      logradouro: "Avenida Brasil",
      complemento: "2piso",
      numero: "1234",
      bairro: "Renovação",
      uf: "RS",
      pais: "Brasil"
    },
    {
      nome: "Beltrana de Fevereiro",
      cpf: "98765432100",
      carteira_unimed: "99887766", 
      data_nascimento: "1995-02-15",
      sexo: "feminino",
      nome_responsavel: "A Responsável",
      telefone_1: "5599123456",
      telefone_2: "5543212355",
      observacoes: "Testando preenchimento automático",
      cep: "95331000",
      cidade: "Bento Gonçalves",
      logradouro: "Rua Argentina",
      complemento: "3o andar",
      numero: "5678",
      bairro: "Centro",
      uf: "RS",
      pais: "Brasil"
    },
    {
      nome: "Ciclano de Março",
      cpf: "12345678900",
      carteira_unimed: "55664488", 
      data_nascimento: "1980-03-20",
      sexo: "masculino",
      nome_responsavel: "A Responsável 2",
      telefone_1: "5599123456",
      telefone_2: "5543212355",
      observacoes: "Outro teste de preenchimento",
      cep: "95332000",
      cidade: "Caxias do Sul",
      logradouro: "Rua da Liberdade",
      complemento: "Apt 101",
      numero: "987",
      bairro: "Independência",
      uf: "RS",
      pais: "Brasil"
    },
    {
      nome: "Diana Smith",
      cpf: "34567890123",
      carteira_unimed: "33889977", 
      data_nascimento: "1992-06-12",
      sexo: "feminino",
      nome_responsavel: "John Smith",
      telefone_1: "5599789012",
      telefone_2: "5598765455",
      observacoes: "Testando observações",
      cep: "12345000",
      cidade: "New York",
      logradouro: "Broadway Street",
      complemento: "Apartment 301",
      numero: "456",
      bairro: "Downtown",
      uf: "NY",
      pais: "United States"
    },
    {
      nome: "John Doe",
      cpf: "65432109876",
      carteira_unimed: "545454", 
      data_nascimento: "1985-08-25",
      sexo: "masculino",
      nome_responsavel: "Jane Doe",
      telefone_1: "1299345678",
      telefone_2: "9876543255",
      observacoes: "Testing remarks",
      cep: "54321000",
      cidade: "Los Angeles",
      logradouro: "Sunset Boulevard",
      complemento: "Suite 501",
      numero: "789",
      bairro: "Hollywood",
      uf: "CA",
      pais: "United States"
    },
    {
      nome: "Maria Silva",
      cpf: "89012345678",
      carteira_unimed: "636363", 
      data_nascimento: "1978-03-07",
      sexo: "feminino",
      nome_responsavel: "José Silva",
      telefone_1: "1099101010",
      telefone_2: "2020202055",
      observacoes: "Teste de observações",
      cep: "56789000",
      cidade: "Rio de Janeiro",
      logradouro: "Rua do Ouvidor",
      complemento: "Bloco B",
      numero: "321",
      bairro: "Centro",
      uf: "RJ",
      pais: "Brasil"
    },
    {
      nome: "João Oliveira",
      cpf: "23456789012",
      carteira_unimed: "98789878", 
      data_nascimento: "1988-12-04",
      sexo: "masculino",
      nome_responsavel: "Maria Oliveira",
      telefone_1: "3099303030",
      telefone_2: "4040404055",
      observacoes: "Testando preenchimento",
      cep: "78901230",
      cidade: "São Paulo",
      logradouro: "Avenida Paulista",
      complemento: "Bloco C",
      numero: "456",
      bairro: "Jardins",
      uf: "SP",
      pais: "Brasil"
    },
    {
      nome: "Ana Souza",
      cpf: "67890123456",
      carteira_unimed: "123456789", 
      data_nascimento: "1990-09-17",
      sexo: "feminino",
      nome_responsavel: "João Souza",
      telefone_1: "5099505050",
      telefone_2: "6060606055",
      observacoes: "Teste de dados",
      cep: "87654321",
      cidade: "Porto Alegre",
      logradouro: "Rua da Praia",
      complemento: "Casa 2",
      numero: "789",
      bairro: "Centro",
      uf: "RS",
      pais: "Brasil"
    },
    {
      nome: "Pedro Santos",
      cpf: "45678901234",
      carteira_unimed: "65865878", 
      data_nascimento: "1997-05-29",
      sexo: "masculino",
      nome_responsavel: "Mariana Santos",
      telefone_1: "7099707070",
      telefone_2: "8080808055",
      observacoes: "Mais um teste",
      cep: "45678901",
      cidade: "Brasília",
      logradouro: "Esplanada dos Ministérios",
      complemento: "Bloco D",
      numero: "1011",
      bairro: "Asa Sul",
      uf: "DF",
      pais: "Brasil"
    },
    {
      nome: "Laura Oliveira",
      cpf: "56789012345",
      carteira_unimed: "426584", 
      data_nascimento: "1994-11-03",
      sexo: "feminino",
      nome_responsavel: "Carlos Oliveira",
      telefone_1: "9099909090",
      telefone_2: "1010101055",
      observacoes: "Teste final",
      cep: "34567890",
      cidade: "Curitiba",
      logradouro: "Rua das Flores",
      complemento: "Casa 3",
      numero: "1213",
      bairro: "Centro",
      uf: "PR",
      pais: "Brasil"
    },
    {
      nome: "Ricardo Gomes",
      cpf: "78901234567",
      carteira_unimed: "123456789", 
      data_nascimento: "1982-07-21",
      sexo: "masculino",
      nome_responsavel: "Ana Gomes",
      telefone_1: "1199112222",
      telefone_2: "3333444455",
      observacoes: "Testando preenchimento automático",
      cep: "23456789",
      cidade: "Salvador",
      logradouro: "Avenida Tancredo Neves",
      complemento: "Bloco E",
      numero: "1415",
      bairro: "Caminho das Árvores",
      uf: "BA",
      pais: "Brasil"
    },
    {
      nome: "Juliana Martins",
      cpf: "01234567890",
      carteira_unimed: "54682167", 
      data_nascimento: "1987-04-13",
      sexo: "feminino",
      nome_responsavel: "Paulo Martins",
      telefone_1: "5599556666",
      telefone_2: "7777888855",
      observacoes: "Testando observações",
      cep: "67890123",
      cidade: "Fortaleza",
      logradouro: "Avenida Beira Mar",
      complemento: "Bloco F",
      numero: "1617",
      bairro: "Meireles",
      uf: "CE",
      pais: "Brasil"
    },
    {
      nome: "Lucas Ferreira",
      cpf: "12345678901",
      carteira_unimed: "63214585", 
      data_nascimento: "1998-02-08",
      sexo: "masculino",
      nome_responsavel: "Patrícia Ferreira",
      telefone_1: "9999990000",
      telefone_2: "1111222255",
      observacoes: "Testando preenchimento",
      cep: "78901234",
      cidade: "Recife",
      logradouro: "Rua do Futuro",
      complemento: "Bloco G",
      numero: "1819",
      bairro: "Boa Viagem",
      uf: "PE",
      pais: "Brasil"
    },
    {
      nome: "Marina Lima",
      cpf: "89012345678",
      carteira_unimed: "5648213456", 
      data_nascimento: "1996-10-25",
      sexo: "feminino",
      nome_responsavel: "José Lima",
      telefone_1: "2299223333",
      telefone_2: "4444555555",
      observacoes: "Teste de observações",
      cep: "89012345",
      cidade: "Porto Velho",
      logradouro: "Avenida Rio Branco",
      complemento: "Bloco H",
      numero: "2021",
      bairro: "Centro",
      uf: "RO",
      pais: "Brasil"
    }
    // Adicione mais dados fictícios conforme necessário
  ];
  
  // Função para selecionar um elemento aleatório da lista
  const selecionarDadosAleatorios = async () => {
    const indiceAleatorio = Math.floor(Math.random() * listaDadosFicticios.length);
    return listaDadosFicticios[indiceAleatorio];
  };
  
  export default selecionarDadosAleatorios;
  