import { toast } from "react-toastify";
import config from "../config/variables.json";

export const getAgenda = async (user, logout, start, end) => {
  console.log( start, end)

  try {
    const response = await fetch(
      `${config.urlServer}/agenta/listar`,
      // `${config.urlServer}/agenda/listar?start=${start.toString()}&end=${end}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
          start, end
        },
      }
    );

    if (!response.ok) {
      let errorMessage = "Erro ao obter agenda.";
      if (response.status === 401) {
        errorMessage = "Sessão expirada!";
        toast.error(errorMessage);
        logout();
      } else {
        const responseData = await response.json();
        if (responseData && responseData.message) {
          errorMessage = responseData.message;
        }
        throw new Error(errorMessage);
      }
    }

    return await response.json();
  } catch (error) {
    console.error("Erro ao obter agenda:", error);
    toast.error("Erro ao obter agenda. Por favor, tente novamente.");
    throw error;
  }
};
