import { useState, createContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import config from "../config/variables.json"
export const AuthContext = createContext({});

function AuthProvider({ children }){
  const [user, setUser] = useState(null)
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();


  useEffect(() => {
    async function loadUser(){
      const storageUser = localStorage.getItem('@clinicaweb')

      if(storageUser){
        setUser(JSON.parse(storageUser))
        setLoading(false);
      }


      setLoading(false);

    }

    loadUser();
  }, [])


  async function signIn(email, password) {
    try {
      setLoadingAuth(true);

      const response = await fetch(`${config.urlServer}/auth/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao efetuar o login";
        toast.error(errorMessage);

        throw new Error(errorMessage);
      }

      const json = await response.json();
      const data = {
        uid: json.user_id, // Substitua por um valor real, se necessário
        token: json.token,
        name: json.name,
        email: json.email,
        admin: json?.admin,
        avatarUrl: json.avatarUrl,
      };

      setUser(data);
      storageUser(data);
      setLoadingAuth(false);
      toast.success("Bem-vindo(a) de volta!");
      navigate("/agenda");
    } catch (error) {
      console.error(error);
      setLoadingAuth(false);
      if (!error) {
        toast.error("Ops, algo deu errado!\n");
      }
    }
  }


  // Cadastrar um novo user
  async function _signUp(email, password, name){
    setLoadingAuth(true);

    console.log(email, password, name)
    setLoadingAuth(false);
    // await createUserWithEmailAndPassword(auth, email, password)
    // .then( async (value) => {
    //     let uid = value.user.uid

    //     await setDoc(doc(db, "users", uid), {
    //       name: name,
    //       avatarUrl: null
    //     })
    //     .then( () => {

    //       let data = {
    //         uid: uid,
    //         name: name,
    //         email: value.user.email,
    //         avatarUrl: null
    //       };

    //       setUser(data);
    //       storageUser(data);
    //       setLoadingAuth(false);
    //       toast.success("Seja bem-vindo ao sistema!")
    //       navigate("/home")
          
    //     })


  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     setLoadingAuth(false);
  //   })

  }

  async function signUp(name, email, password, confirmPassword) {
    try {
      setLoadingAuth(true);

      // Verifica se as senhas coincidem
      if (password !== confirmPassword) {
        console.log(password, confirmPassword);
        throw new Error("As senhas não coincidem");
      }

      const response = await fetch(`${config.urlServer}/auth/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, password, confirmPassword }),
      });

      if (response.status !== 200 && response.status !== 201) {
        const errorData = await response.json();
        const errorMessage =
          errorData.msg || "Erro desconhecido ao criar o usuário";
        throw new Error(errorMessage);
      }

      const json = await response.json();

      const data = {
        uid: json.user_id, // Substitua por um valor real, se necessário
        token: json.token,
        name: json.name,
        email: json.email,
        avatarUrl: json.avatarUrl,
      };

      setUser(data);
      storageUser(data);
      setLoadingAuth(false);
      toast.success("Usuário criado com sucesso!");
      navigate("/home");
    } catch (error) {
      console.error(error);
      setLoadingAuth(false);
      toast.error(error.msg || "Ops, algo deu errado!");
    }
  }

  function storageUser(data){
    localStorage.setItem('@clinicaweb', JSON.stringify(data))
  }

  async function logout(){
    // await signOut(auth);
    localStorage.removeItem('@clinicaweb');
    setUser(null);
  }

  return(
    <AuthContext.Provider 
      value={{
        signed: !!user,
        user,
        signIn,
        signUp,
        logout,
        loadingAuth,
        loading,
        storageUser,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;