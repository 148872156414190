import React, { useState, useEffect, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { getPacientes } from "../../api/getPacientes";
import { AuthContext } from "../../contexts/auth";
import { formatCpf } from "../../functions/formatCPF";
import config from "../../config/variables.json";
import "./modalNovoAgendamento.css";

export default function ModalNovoAgendamento({
  closeModalNovoAgendamento,
  paciente,
  selectedGuia,
}) {
  const { user, logout } = useContext(AuthContext);
  const [guia, setGuia] = useState();
  const [loading, setLoading] = useState(false);
  const [nextAvailableDays, setNextAvailableDays] = useState([]);

  useEffect(() => {
    calculateNextAvailableDays();
  }, []);

  const calculateNextAvailableDays = () => {
    const today = new Date();
    const weekdays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
    const nextDays = [];

    // Adiciona os próximos 5 dias úteis
    for (let i = 0; nextDays.length < 14; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);

      if (nextDate.getDay() !== 0 && nextDate.getDay() !== 6) {
        const formattedDate = `${nextDate.getDate()}/${
          nextDate.getMonth() + 1
        }/${nextDate.getFullYear()}`;
        const dayOfWeek = weekdays[nextDate.getDay()];

        const availableTimes = generateAvailableTimes();
        nextDays.push({ date: formattedDate, dayOfWeek, availableTimes });
      }
    }

    setNextAvailableDays(nextDays);
  };

  const generateAvailableTimes = () => {
    // Gere os horários de atendimento, por exemplo: 08:00, 08:30, etc.
    const availableTimes = [];
    const startHour = 7;
    const endHour = 19;
    const interval = 60;

    for (let hour = startHour; hour < endHour; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour
          .toString()
          .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
        availableTimes.push({ time, selected: false }); // Adicione a propriedade selected
      }
    }

    return availableTimes;
  };

  const __generateAvailableTimes = () => {
    // para testes
    // Gere os horários de atendimento, por exemplo: 08:00, 08:30, etc.
    const availableTimes = [];
    const startHour = 16;
    const endHour = 17;
    const interval = 5;

    for (let hour = startHour; hour < endHour; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        const time = `${hour
          .toString()
          .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
        availableTimes.push({ time, selected: false }); // Adicione a propriedade selected
      }
    }

    return availableTimes;
  };
  const [selectedTimes, setSelectedTimes] = useState([]);
  const handleTimeClick = (time, day) => {
    const isSelected = selectedTimes.some(
      (item) => item.date === day.date && item.time === time
    );

    if (isSelected) {
      // Se já estiver selecionado, remova da lista
      const updatedTimes = selectedTimes.filter(
        (item) => !(item.date === day.date && item.time === time)
      );
      setSelectedTimes(updatedTimes);
      console.log(updatedTimes);
    } else {
      // Se não estiver selecionado, adicione à lista
      const updatedTimes = [...selectedTimes, { date: day.date, time }];
      setSelectedTimes(updatedTimes);
      //   console.log(updatedTimes);
    }
  };

  const closeModal = () => {
    closeModalNovoAgendamento();
  };

  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let data = {
      selectedTimes,
      id_guia: guia?._id,
      numero_guia_operadora: guia?.numero_guia_operadora,
      carteira_unimed: paciente?.carteira_unimed,
      id_paciente: paciente?._id,
    };
    console.log("Dados do formulário:", data);
    await realizarAgendamento(data)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const realizarAgendamento = async (data) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/agenda/criar-agendamento/horarios-selecionados`, // Rota para criar guias/cadastros/guias-operadoras/paciente/criar
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status !== 201) {
        const errorData = await response.json();
        const errorMessage =
          errorData.error || "Erro desconhecido ao realizar agendamentos";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      closeModal();
      setLoading(false);
      toast.success("Agendamento realizado com sucesso!");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedGuia) {
      contabilizarStatusGuia();
    }
    return () => {};
  }, [selectedGuia]);

  const contabilizarStatusGuia = async (e) => {
    setLoading(true);
    if (e) {
      e.preventDefault();
    }
    try {
      const response = await fetch(
        `${config.urlServer}/cadastros/guias-operadoras/paciente/guia/contabilizar-status/${selectedGuia?._id}`, // Rota para criar guias/cadastros/guias-operadoras/paciente/criar
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
          body: JSON.stringify({}),
        }
      );

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage =
          errorData.error || "Erro desconhecido ao contabilizar";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }
      const data = await response.json();
      console.log(data);
      // closeModal();
      setGuia(data);
      setLoading(false);
      // toast.success("Contabilizado com sucesso!");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <section className="content-modal-novo-agendamento">
      <form onSubmit={handleFormSubmit}>
        <header>
          <h2>Novo Agendamento</h2>
          <button onClick={closeModal}>
            <AiOutlineClose color="rgb(50,50,50)" size={24} />
          </button>
        </header>
        <main>
          <section>
            <div>
              <label>Nome:</label>
              <input value={paciente.nome} disabled />
            </div>
            <div>
              <label>CPF:</label>
              <input value={formatCpf(paciente.cpf)} disabled />
            </div>
            <div>
              <label>Nome responsavel:</label>
              <input value={paciente?.nome_responsavel} disabled />
            </div>
          </section>
          <section>
            <div>
              <label>Guia Operadora:</label>
              <input value={guia?.numero_guia_operadora} disabled />
            </div>
            <div>
              <label>Descrição da Guia:</label>
              <input value={guia?.descricao_guia} disabled />
            </div>
          </section>
          <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
            <div>
              <label>Total</label>
              <input
                style={{ width: 60 }}
                value={guia?.quantidade_total}
                disabled
              />
            </div>
            <div>
              <label>Disponível</label>
              <input
                style={{ width: 60 }}
                value={
                  guia?.quantidade_total - guia?.quantidade_executada_manual
                }
                // verificar conta
                disabled
              />
            </div>

            <div>
              <label>Manual</label>
              <input
                style={{ width: 60 }}
                value={guia?.quantidade_executada_manual}
                disabled
              />
            </div>

            <div>
              <label>Robô</label>
              <input
                style={{ width: 60 }}
                value={guia?.quantidade_executada_robo}
                disabled
              />
            </div>
            <div>
              <label>Agendada</label>
              <input
                style={{ width: 60 }}
                value={guia?.quantidade_agendada}
                disabled
              />
            </div>

            <div>
              <label>Cancelada</label>
              <input
                style={{ width: 60 }}
                value={guia?.quantidade_cancelada}
                disabled
              />
            </div>

            <div>
              <label>Erro</label>
              <input
                style={{ width: 60 }}
                value={guia?.quantidade_erro}
                disabled
              />
            </div>
            {/* <div>
                  <button onClick={(e) => contabilizarStatusGuia(e)}>
                    Contabilizar guias
                  </button>
                </div> */}
          </div>
          <div>
            <label>Dias e horários para executar:</label>
            <table className="table-days-times">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Dia da semana</th>
                  <th>Horários</th>
                </tr>
              </thead>
              <tbody>
                {nextAvailableDays.map((day) => (
                  <tr key={day.date}>
                    <td>{day.date}</td>
                    <td>{day.dayOfWeek}</td>
                    <td>
                      {day.availableTimes.map((time) => (
                        <span
                          // className="selected"
                          key={time.time}
                          className={
                            selectedTimes.some(
                              (item) =>
                                item.date === day.date &&
                                item.time === time.time
                            )
                              ? "selected"
                              : ""
                          }
                          onClick={() => handleTimeClick(time.time, day)}
                        >
                          {time.time}{" "}
                        </span>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
        <footer>
          <div>
            {selectedTimes.length >
            guia?.quantidade_total -
              guia?.quantidade_agendada -
              guia?.quantidade_executada_manual ? (
              <label
                style={{
                  color: "#fff",
                  padding: 2,
                  backgroundColor: "red",
                  borderRadius: 2,
                }}
              >
                Atenção, a quantidade de sessões disponíveis é{" "}
                <strong>
                  {guia?.quantidade_total -
                    guia?.quantidade_agendada -
                    guia?.quantidade_executada_manual}
                </strong>{" "}
                e foram selecionadas <strong>{selectedTimes.length}</strong>.
              </label>
            ) : (
              <label>Quantidade selecionada: {selectedTimes.length || 0}</label>
            )}
          </div>
          {loading ? (
            // <button disabled> Carregando...</button>
            <button type="submit">Salvar</button>
          ) : (
            <button type="submit">Salvar</button>
          )}
        </footer>
      </form>
    </section>
  );
}
