import React, { useState } from "react";
import { FiMessageSquare } from "react-icons/fi";
import Header from "../../components/Header";
import Title from "../../components/Title";
import ProfissionaisLista from "../../components/ListaProfissionais";

export default function Profissionais() {
  const profissionais = [
    {
      avatar: "avatar1.jpg",
      nome: "João Silva",
      responsavel: "Maria Silva",
      cpf: "123.456.789-00",
      cidade: "São Paulo",
      email: "joao.silva@example.com",
      telefone: "(11) 1234-5678",
    },
    {
      avatar: "avatar2.jpg",
      nome: "Ana Souza",
      responsavel: "Carlos Souza",
      cpf: "987.654.321-00",
      cidade: "Rio de Janeiro",
      email: "ana.souza@example.com",
      telefone: "(21) 9876-5432",
    },
    {
      avatar: "avatar3.jpg",
      nome: "Pedro Oliveira",
      responsavel: "Fernanda Oliveira",
      cpf: "456.789.123-00",
      cidade: "Belo Horizonte",
      email: "pedro.oliveira@example.com",
      telefone: "(31) 4567-8901",
    },
  ];

  return (
    <div style={{ display: "flex" }}>
      <Header />

      <div className="content">
        <Title name="Cadastros / Profissionais">
          <FiMessageSquare size={25} />
        </Title>
        <ProfissionaisLista profissionais={profissionais} />
      </div>
    </div>
  );
}

// export default ProfissionaisTabela;
