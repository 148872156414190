import { toast } from "react-toastify";
import config from "../config/variables.json";

export const getPacientes = async (user, logout) => {
  try {
    const response = await fetch(
      `${config.urlServer}/cadastros/pacientes/listar`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          email: user.email,
          token: user.token,
        },
      }
    );

    if (!response.ok) {
      let errorMessage = "Erro ao obter pacientes.";
      if (response.status === 401) {
        errorMessage = "Sessão expirada!";
        toast.error(errorMessage);
        logout();
      } else {
        const responseData = await response.json();
        if (responseData && responseData.message) {
          errorMessage = responseData.message;
        }
        throw new Error(errorMessage);
      }
    }

    return await response.json();
  } catch (error) {
    console.error("Erro ao obter pacientes:", error);
    toast.error("Erro ao obter pacientes. Por favor, tente novamente.");
    throw error;
  }
};
