import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "./formularioCadatro.css";
import { busca_cep } from "../../api/busca_cep";
import { toast } from "react-toastify";
import selecionarDadosAleatorios from "./selecionarDadosAleatorios";
import { useEffect } from "react";

const schema = yup
  .object({
    // mainEmail: yup.string(),
    // birthDate: yup.number().positive().integer().required(),
  })
  .required();

export default function FormularioCadastroPaciente({
  action,
  paciente,
  loading,
}) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // const onSubmit = (data) => validateData(data);
  const onSubmit = (dataForm) => action(dataForm);

  useEffect(() => {
    if (paciente) {
      loadDataPaciente(paciente);
    }
  }, [paciente]);

  const autoPreencher = async () => {
    const data = await selecionarDadosAleatorios();
    setValue("nome", data.nome);
    setValue("cpf", data.cpf);
    setValue("carteira_unimed", data.carteira_unimed);
    setValue("data_nascimento", data.data_nascimento);
    setValue("sexo", data.sexo);
    setValue("nome_responsavel", data.nome_responsavel);
    setValue("telefone_1", data.telefone_1);
    setValue("telefone_2", data.telefone_2);
    setValue("observacoes", data.observacoes);
    setValue("endereco.cep", data.cep);
    setValue("endereco.cidade", data.cidade);
    setValue("endereco.logradouro", data.logradouro);
    setValue("endereco.complemento", data.complemento);
    setValue("endereco.numero", data.numero);
    setValue("endereco.bairro", data.bairro);
    setValue("endereco.uf", data.uf);
    setValue("endereco.pais", data.pais);
  };

  const loadDataPaciente = async (data) => {
    if (data) {
      setValue("_id", data?._id);
      setValue("nome", data?.nome);
      setValue("cpf", data?.cpf);
      setValue("carteira_unimed", data?.carteira_unimed);
      setValue("data_nascimento", data?.data_nascimento.split("T")[0]);
      setValue("sexo", data?.sexo);
      setValue("nome_responsavel", data?.nome_responsavel);
      setValue("telefone_1", data?.telefone_1);
      setValue("telefone_2", data?.telefone2);
      setValue("observacoes", data?.observacoes);
      setValue("endereco.cep", data?.endereco?.cep);
      setValue("endereco.cidade", data?.endereco?.cidade);
      setValue("endereco.logradouro", data?.endereco?.logradouro);
      setValue("endereco.complemento", data?.endereco?.complemento);
      setValue("endereco.numero", data?.endereco?.numero);
      setValue("endereco.bairro", data?.endereco?.bairro);
      setValue("endereco.uf", data?.endereco?.uf);
      setValue("endereco.pais", data?.endereco?.pais);
    }
  };

  const cepAutoCompletaEndereco = async (e) => {
    const cepValue = e.target.value;
    console.log(cepValue);
    busca_cep(cepValue)
      .then((data) => {
        console.log(data);
        setValue("endereco.cidade", data?.localidade);
        setValue("endereco.logradouro", data?.logradouro);
        setValue("endereco.complemento", data?.complemento);
        setValue("endereco.bairro", data?.bairro);
        setValue("endereco.uf", data?.uf);
        setValue("endereco.pais", "Brasil");
      })
      .catch(() => {
        // Handle error
        toast.error("Não foi possivel encontrar dados do cep!");
      });
  };


  const handleChange = (e) => {
    console.log(e.target.id)
    // Remove todos os caracteres que não são números
    const value = e.target.value.replace(/\D/g, '');
    setValue(e.target.id, value)
  };

  return (
    <div className="section-cadastro-paciente">
      {/* <button
        onClick={(e) => {
          autoPreencher();
        }}
      >
        Auto preencher
      </button> */}
      <h2>Paciente Cadastro</h2>
      <form
        className="form-cadastro-paciente"
        onSubmit={handleSubmit(onSubmit)}
        value={watch("cadastro-paciente")}
        // {...register("cadastro-paciente")}
      >
        <div className="form-group">
          <label htmlFor="nome">Nome *</label>
          <input
            id="nome"
            type="text"
            name="nome"
            {...register("nome")}
            placeholder="Digite o nome do paciente"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="cpf">CPF *</label>
          <input
            id="cpf"
            type="text"
            name="cpf"
            {...register("cpf")}
            onChange={handleChange}
            placeholder="Digite o CPF do paciente"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="carteira_unimed">Carteira Unimed *</label>
          <input
            id="carteira_unimed"
            type="text"
            name="carteira_unimed"
            {...register("carteira_unimed")}
            onChange={handleChange}
            placeholder="Digite a carteira do paciente"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="data_nascimento">Data de nascimento *</label>
          <input
            id="data_nascimento"
            type="date"
            name="data_nascimento"
            {...register("data_nascimento")}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="sexo">Sexo</label>
          <select
            id="sexo"
            type="select"
            name="sexo"
            {...register("sexo")}
            style={{ padding: 5 }}
            defaultValue={''}
          >
            <option value="" disabled >
              Selecione o sexo
            </option>
            <option value="masculino">Masculino</option>
            <option value="feminino">Feminino</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="nome_responsavel">Nome responsável *</label>
          <input
            id="nome_responsavel"
            type="text"
            name="nome_responsavel"
            {...register("nome_responsavel")}
            placeholder="Digite o nome do responsável pelo paciente"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="telefone_1">Telefone 1</label>
          <input
            id="telefone_1"
            type="text"
            name="telefone_1"
            {...register("telefone_1")}
            placeholder="Digite o telefone principal"
          />
        </div>

        <div className="form-group">
          <label htmlFor="telefone_2">Telefone 2</label>
          <input
            id="telefone_2"
            type="text"
            name="telefone_2"
            {...register("telefone_2")}
            placeholder="Digite o telefone secundário"
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.cep">CEP</label>
          <input
            id="endereco.cep"
            name="endereco.cep"
            {...register("endereco.cep")}
            onBlur={cepAutoCompletaEndereco}
            placeholder="Digite o CEP"
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.logradouro">Logradouro</label>
          <input
            id="endereco.logradouro"
            type="text"
            name="endereco.logradouro"
            {...register("endereco.logradouro")}
            placeholder="Digite o nome da rua"
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.numero">Número</label>
          <input
            id="endereco.numero"
            type="text"
            name="endereco.numero"
            {...register("endereco.numero")}
            placeholder="Digite o número da residência"
          />
        </div>
        <div className="form-group">
          <label htmlFor="endereco.complemento">Complemento</label>
          <input
            id="endereco.complemento"
            type="text"
            name="endereco.complemento"
            {...register("endereco.complemento")}
            placeholder="Digite o complemento do endereço"
          />
        </div>
        <div className="form-group">
          <label htmlFor="endereco.bairro">Bairro</label>
          <input
            id="endereco.bairro"
            type="text"
            name="endereco.bairro"
            {...register("endereco.bairro")}
            placeholder="Digite o bairro"
          />
        </div>
        <div className="form-group">
          <label htmlFor="endereco.cidade">Cidade</label>
          <input
            id="endereco.cidade"
            type="text"
            name="endereco.cidade"
            {...register("endereco.cidade")}
            placeholder="Digite a cidade"
          />
        </div>
        <div className="form-group">
          <label htmlFor="endereco.uf">UF</label>
          <input
            id="endereco.uf"
            type="text"
            name="endereco.uf"
            {...register("endereco.uf")}
            placeholder="Digite a sigla do estado"
          />
        </div>
        <div className="form-group">
          <label htmlFor="endereco.pais">País</label>
          <input
            id="endereco.pais"
            type="text"
            name="endereco.pais"
            {...register("endereco.pais")}
            placeholder="Digite o país"
          />
        </div>

        <div className="form-group">
          <label htmlFor="observacoes">Observações</label>
          <textarea
            id="observacoes"
            type="text"
            name="observacoes"
            {...register("observacoes")}
            placeholder="Observações do paciente"
          />
        </div>

        {loading ? (
          <button disabled className="button-submit-cadastro-paciente">Aguarde...</button>
        ) : (
          <button type="submit" className="button-submit-cadastro-paciente">
            Salvar
          </button>
        )}
      </form>
    </div>
  );
}
