import { useState, useContext } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { FiMessageSquare } from "react-icons/fi";
import Header from "../../components/Header";
import Title from "../../components/Title";
import FormularioCadastroGuia from "../../components/FormularioCadastroGuia";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import config from '../../config/variables.json';

export default function CriarGuiaOperadora() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { paciente } = location.state;

  const criarNovaGuia = async (data) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/cadastros/guias-operadoras/paciente/criar`, // Rota para criar guias/cadastros/guias-operadoras/paciente/criar
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
          body: JSON.stringify({...data, id_paciente: paciente?._id}),
        }
      );

      if (response.status !== 201) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Erro desconhecido ao criar guia";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      setLoading(false);
      toast.success("Guia criada com sucesso!");
      navigate(`/cadastros/guias-operadoras/paciente/${paciente?._id}`, {state:{ paciente }})
    } catch (error) {
      console.error(error);
      setLoading(false);

    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Header />
      <div className="content">
        <Title name="Cadastros / Guias Operadoras / Nova Guia">
          <FiMessageSquare size={25} />
        </Title>
        
        <FormularioCadastroGuia action={criarNovaGuia} paciente={paciente} loading={loading}/>
      </div>
    </div>
  );
}
