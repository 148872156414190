import { Routes, Route } from 'react-router-dom'

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Home from '../pages/Home'
import Profile from '../pages/Profile'
import Customers from '../pages/Customers'
import New from '../pages/New'

import Private from './Private'
import Profissionais from '../pages/Profissionais';
import Pacientes from '../pages/Pacientes';
import Agenda from '../pages/Agenda';
import CriarPacientes from '../pages/CriarPacientes';
import EditarPaciente from '../pages/EditarPaciente';
import GuiasOperadoras from '../pages/GuiasOperadoras';
import CriarGuiaOperadora from '../pages/CriarGuiaOperadora';
import EditarGuiaOperadora from '../pages/EditarGuiaOperadora';

function RoutesApp(){
  return(
    <Routes>
      <Route path="/login" element={ <SignIn/> } />
      <Route path="/" element={ <Agenda/> } />
      <Route path="/register" element={ <SignUp/> } />
      {/* <Route path="/home" element={ <Private><Home/></Private> } /> */}
      <Route path="/home" element={ <Home/> } />


      <Route path="/profile" element={ <Private><Profile/></Private> } />

      <Route path="/customers" element={<Private><Customers/></Private>} />
      
      <Route path="/new" element={<Private><New/></Private>} />

      <Route path="/new/:id" element={<Private><New/></Private>} />

      <Route path="/cadastros/profissionais" element={ <Private><Profissionais/></Private> } /> 
      <Route path="/cadastros/pacientes" element={ <Private><Pacientes/></Private> } /> 
      <Route path='/cadastros/pacientes/editar/:id' element={<Private><EditarPaciente/></Private>}/>
      <Route path='/cadastros/pacientes/criar-novo' element={<Private><CriarPacientes/></Private>}/>
      <Route path='/cadastros/guias-operadoras/paciente/:id' element={<Private><GuiasOperadoras/></Private>}/>
      <Route path='/cadastros/guias-operadoras/paciente/criar-nova' element={<Private><CriarGuiaOperadora/></Private>}/>
      <Route path='/cadastros/guias-operadoras/paciente/editar/:id' element={<Private><EditarGuiaOperadora/></Private>}/>

      <Route path='/agenda' element={ <Private><Agenda /></Private> } />

      
    </Routes>
  )
}

export default RoutesApp;