import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import "./formularioCadatroGuia.css";
import { useEffect } from "react";

const schema = yup
  .object({
    numero_guia_operadora: yup
      .string()
      .required("O número da guia é obrigatório"),
  })
  .required();

export default function FormularioCadastroGuia({ action, paciente, guia, loading }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await action(data); // Envia os dados para a função de criação de guia
      // toast.success("Guia criada com sucesso!");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar guia");
    }
  };

  useEffect(() => {
    if (guia) {
      loadDataGuia(guia);
    }
  }, [guia]);

  const loadDataGuia = async (data) => {
    if (data) {
      setValue("numero_guia_operadora", data?.numero_guia_operadora);
      setValue("descricao_guia", data?.descricao_guia);
      setValue(
        "quantidade_executada_manual",
        data?.quantidade_executada_manual
      );
      setValue("quantidade_total", data?.quantidade_total);
    }
  };

  return (
    <div className="section-cadastro-guia">
      <h2>Cadastro de Guia</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>
          Paciente:{" "}
          <i>
            {paciente?.nome} <br /> CPF:{" "}
            {paciente?.cpf?.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              "$1.$2.$3-$4"
            )}
          </i>
        </h4>
        <div className="form-group">
          <label htmlFor="numero_guia_operadora">
            Número da Guia Operadora *
          </label>
          <input
            id="numero_guia_operadora"
            type="text"
            {...register("numero_guia_operadora")}
            required
            placeholder="Digite o número da guia"
          />
          {errors.numero_guia_operadora && (
            <p>{errors.numero_guia_operadora.message}</p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="descricao_guia">Descrição da Guia *</label>
          <input
            id="descricao_guia"
            type="text"
            {...register("descricao_guia")}
            required
            placeholder="Digite a descrição da guia"
          />
          {errors.descricao_guia && <p>{errors.descricao_guia.message}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="quantidade_executada_manual">
            Quantidade Executada Manual *
          </label>
          <input
            id="quantidade_executada_manual"
            type="number"
            {...register("quantidade_executada_manual")}
            required
            placeholder="Digite a quantidade de vezes que foi executada manual"
          />
          {errors.quantidade_executada_manual && (
            <p>{errors.quantidade_executada_manual.message}</p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="quantidade_total">Quantidade Total *</label>
          <input
            id="quantidade_total"
            type="number"
            {...register("quantidade_total")}
            required
            placeholder="Digite quantidade disponível da guia"
          />
          {errors.quantidade_total && <p>{errors.quantidade_total.message}</p>}
        </div>


        {loading ? (
          <button disabled className="button-submit-cadastro-guia">Aguarde...</button>
        ) : (
        <button type="submit" className="button-submit-cadastro-guia">
          Salvar
        </button>
        )}
      </form>
    </div>
  );
}
