import { useState, useEffect, useContext } from "react";
import { FiMessageSquare } from "react-icons/fi";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

import Title from "../../components/Title";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/auth";
import PacientesLista from "../../components/ListaPacientes";
import { getPacientes } from "../../api/getPacientes";

export default function Pacientes() {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [pacientes, setPacientes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getPacientes(user, logout);
      setPacientes(data);
    } catch (error) {
      toast.error("Erro ao carregar pacientes. Por favor, tente novamente.");
      console.error("Erro ao carregar pacientes:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ display: "flex" }}>
      <Header />
      <div className="content">
        <Title name="Cadastros / Pacientes">
          <FiMessageSquare size={25} />
        </Title>
        {loading ? (
          <div>Carregando...</div>
        ) : (
          <PacientesLista
            pacientes={pacientes}
            user={user}
            getPacientes={fetchData}
          />
        )}
      </div>
    </div>
  );
}
