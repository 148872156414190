import { useEffect, useState, useContext } from "react";
import Header from "../../components/Header";
import Title from "../../components/Title";
import "./agenda.css";
import ListaAgenda from "../../components/ListaAgenda";
import Modal from "react-modal";
import ModalNovoAgendamento from "../../components/ModalNovoAgendamento";
import { AuthContext } from "../../contexts/auth";
import { getAgenda } from "../../api/getAgenda";
import { toast } from "react-toastify";
import config from '../../config/variables.json'

Modal.setAppElement("#root"); // Para ajudar com acessibilidade
// Estilo para centralizar o modal
const customStylesRules = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%", // Defina a largura desejada
    // minWidth: '850px',
    maxWidth: "850px", // Defina uma largura máxima opcional
    height: "95vh", // Defina a altura desejada (exemplo: 60% da altura da viewport)
    maxHeight: "95vh", // Defina uma altura máxima opcional
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.3)", // Cor de fundo do overlay (fundo escuro semitransparente)
    zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
  },
};

export default function Agenda() {
  const { user, logout } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const [tipoAgenda, setTipoAgenda] = useState("lista");
  const [modalNovoAgendamentoIsOpen, setModalNovoAgendamentoIsOpen] = useState(
    false
  );
  const [agenda, setAgenda] = useState([]);

  const date = new Date();

  let nDate =
    parseInt(date.getDate()) > 9 ? date.getDate() : `0${date.getDate()}`;
  let nMonth_ = date.getMonth();
  let nMonth =
    parseInt(nMonth_) > 9 ? parseInt(nMonth_) : `0${parseInt(nMonth_)}`;
  let nYear = date.getFullYear();

  let data_inicio = new Date(nYear, nMonth, nDate);
  data_inicio.setHours(0, 0, 0, 0);
  data_inicio.setDate(data_inicio.getDate() - 15);

  let data_fim = new Date(nYear, nMonth, nDate);
  data_fim.setHours(0, 0, 0, 0);
  data_fim.setDate(data_fim.getDate() + 15);


  const openModalNovoAgendamento = () => {
    setModalNovoAgendamentoIsOpen(true);
  };

  const closeModalNovoAgendamento = () => {
    setModalNovoAgendamentoIsOpen(false);
  };

  useEffect(() => {


    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getAgenda(user, logout, data_inicio, data_fim);
      console.log(data);
      setAgenda(data);
    } catch (error) {
      toast.error("Erro ao carregar pacientes. Por favor, tente novamente.");
      console.error("Erro ao carregar pacientes:", error);
    } finally {
      setLoading(false);
    }
  };

  const _actionDelete = async (data) => {
      console.log(data._id)
  }

  const actionDelete = async (data) => {
    let { paciente, cpf, _id } = data;

    const confirmarExclusao = window.confirm(`Tem certeza que deseja excluir o agendamento?
    Dados do agendamento:
      ${paciente}
      
  Lembrando que, uma vez feita esta ação, não será possível reverter.
      `);

    if (_id && confirmarExclusao) {
      setLoading(true);
      const response = await fetch(
        `${config.urlServer}/agenda/deletar/${_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
        }
      );
      if (!response.ok) {
        toast.error("Erro ao excluir Agemdamento");
        throw new Error("Erro ao excluir Agemdamento");
      }

      const agendamentoRemovido = await response.json();
      toast.success("Agemdamento excluído com sucesso!");
      setLoading(false);
      return fetchData();
    }
  };

  if (tipoAgenda === "lista") {
    return (
      <div style={{ display: "flex" }}>
        <Header />
        <div className="content">
          <Title name={"Agenda"} />
          {/* <button onClick={()=>{openModalNovoAgendamento()}}>Novo agendamento</button> */}
          <button
            onClick={() => {
              fetchData();
            }}
            className="btn-atualizar-agenda"
          >
            Atualizar
          </button>
      
          <ListaAgenda
            datas={agenda.datas}
            // periodo_selecionado={}
            periodo_selecionado_final={agenda?.periodo_selecionado?.split(' - ')[1]}
            periodo_selecionado_inicio={agenda?.periodo_selecionado?.split(' - ')[0]}
            actionDelete={actionDelete}
        
          />

          <Modal
            isOpen={modalNovoAgendamentoIsOpen}
            onRequestClose={closeModalNovoAgendamento}
            style={customStylesRules}
          >
            <ModalNovoAgendamento
              user={user}
              closeModalNovoAgendamento={closeModalNovoAgendamento}
            />
          </Modal>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <div className="">
          <Title name={"Agenda"} />
        </div>
        <span style={{ textAlign: "center", marginLeft: "50%" }}>
          Em desenvolvimento...
        </span>
      </div>
    );
  }
}
