import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation  } from "react-router-dom";

import { FiMessageSquare } from "react-icons/fi";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/auth";
import config from '../../config/variables.json';
import GuiasLista from "../../components/ListaGuias";

export default function GuiasOperadoras() {
  const location = useLocation();
  const { id } = useParams();
  const { paciente } = location.state;
  const [guias, setGuias] = useState([]);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  // const [paciente, setPaiente] = useState(null)




  useEffect(() => {
    getGuias();
    console.log("paciente", paciente )

  }, []);

  const getGuias = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${config.urlServer}/cadastros/guias-operadoras/paciente/guias/${id}`,
        { 
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Erro ao obter guias operadoras");
      }
      const data = await response.json();
      setGuias(data);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao obter guias operadoras");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Header />
      <div className="content">
        <Title name="Cadastros / Guias Operadoras">
          <FiMessageSquare size={25} />
        </Title>
        <section>
          <GuiasLista guias={guias} paciente={paciente} user={user} getGuias={getGuias} />
        </section>
      </div>
    </div>
  );
}
