import "./listaAgenda.css";
import { addMinutes, format } from "date-fns";
import {
  FaPencilAlt,
  FaTrashAlt,
  FaRegEye,
  FaBarcode,
  FaCalendarAlt,
} from "react-icons/fa";
import { RiVerifiedBadgeFill } from "react-icons/ri";

// Dentro do loop onde você está mapeando os agendamentos
export default function ListaAgenda({
  datas,
  periodo_selecionado_inicio,
  periodo_selecionado_final,
  actionDelete,
}) {
  return (
    <section className="section-lista-periodo">
      <h3>
        {`${periodo_selecionado_inicio?.split("/")[1]}/${
          periodo_selecionado_inicio?.split("/")[0]
        }/${periodo_selecionado_inicio?.split("/")[2]}`}{" "}
        -{" "}
        {`${periodo_selecionado_final?.split("/")[1]}/${
          periodo_selecionado_final?.split("/")[0]
        }/${periodo_selecionado_final?.split("/")[2]}`}
      </h3>

      <section className="section-lista-periodo-dias">
        {datas?.map((data) => {
          let dateArray = data.dia.split("-");
          let dia = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

          return (
            <section className="lista-periodo-dia" key={data.dia}>
              <div className="lista-periodo-dia-data">
                <span>{dia}</span>
                <span>
                  {new Date(dateArray).toLocaleDateString("pt-BR", {
                    weekday: "long",
                  })}
                </span>
              </div>
              <div className="periodo-dia-agendamentos">
                {/*  */}
                <article
                  className="periodo-dia-agendamento"
                  style={{ fontWeight: "bold" }}
                >
                  <div id="periodo-dia-agendamento-div-1">
                    <div style={{ maxWidth: 100 }}>
                      <span>{`Horário`}</span>
                    </div>
                    <div>
                      <span>{"Paciente"}</span>
                    </div>
                  </div>
                  <div id="periodo-dia-agendamento-div-2">
                    <div style={{ width: 150 }}>
                      <span>{"Guia Operadora"}</span>
                    </div>
                    <div style={{ width: 200 }}>
                      <div style={{ width: 150 }}>
                        <span>{"Atualizado em"}</span>
                      </div>
                      <div style={{ width: 50 }}>
                        <span>{"Status"}</span>
                      </div>
                    </div>
                  </div>
                </article>
                {/*  */}
                {data?.agendamento.map((agendamento, index) => {
                  // Calcular o horário final adicionando o tempo de atendimento
                  const horarioFinal = addMinutes(
                    new Date(agendamento.horario),
                    parseInt(agendamento.tempo_atendimento)
                  );
                  return (
                    <article className="periodo-dia-agendamento" key={index}>
                      <div id="periodo-dia-agendamento-div-1">
                        <div style={{ maxWidth: 100 }}>
                          <span>
                            {`${format(
                              new Date(agendamento.horario),
                              "HH:mm"
                            )} - ${format(horarioFinal, "HH:mm")}`}
                          </span>
                        </div>
                        <div>
                          <span>{agendamento?.paciente}</span>
                        </div>
                      </div>
                      <div id="periodo-dia-agendamento-div-2">
                        <div style={{ width: 150 }}>
                          <span>{agendamento?.guia_operadora}</span>
                        </div>
                        <div style={{ width: 200 }}>
                          <div style={{ width: 140 }}>
                            <span>
                              {format(
                                new Date(agendamento?.updatedAt),
                                "HH:mm:ss"
                              )}
                            </span>
                          </div>
                          <div style={{ width: 80 }}>
                            <span>
                              {agendamento.status === "agendado" ? (
                                <FaCalendarAlt
                                  color={"var(--primary-default)"}
                                />
                              ) : agendamento.status === "realizado" ? (
                                <RiVerifiedBadgeFill color={"var(--green)"} />
                              ) : agendamento.status === "cancelado" ? (
                                <FaCalendarAlt />
                              ) : (
                                "?"
                              )}
                            </span>
                            <span>
                              <button
                                style={{
                                  color: "#f64846",
                                  backgroundColor: 'transparent',
                                  border: 0,
                                }}
                                onClick={() => actionDelete(agendamento)}
                              >
                                <FaTrashAlt />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </article>
                  );
                })}
              </div>
            </section>
          );
        })}
      </section>
    </section>
  );
}
