import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { FiMessageSquare } from "react-icons/fi";
import Header from "../../components/Header";
import Title from "../../components/Title";
import FormularioCadastroPaciente from "../../components/FormularioCadastroPaciente";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/auth";

import config from "../../config/variables.json";

export default function CriarPacientes() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const criar_novo_paciente = async (data) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${config.urlServer}/cadastros/pacientes/criar`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status !== 201) {
        const errorData = await response.json();
        const errorMessage =
          errorData.error || "Erro desconhecido ao criar paciente";
        toast.error(errorMessage);

        throw new Error(errorMessage);
      }

      const json = await response.json();

      setLoading(false);
      toast.success("Paciente criado com sucesso!");
      navigate(`/cadastros/pacientes`)
    } catch (error) {
      console.log(error)
      toast.error(error)
      setLoading(false)
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Header />

      <div className="content">
        <Title name="Cadastros / Pacientes / Novo Paciente">
          <FiMessageSquare size={25} />
        </Title>


          <FormularioCadastroPaciente loading={loading} action={criar_novo_paciente} />
        
      </div>
    </div>
  );
}
