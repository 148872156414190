import { useContext } from "react";
import avatarImg from "../../assets/avatar.png";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { FiHome, FiSettings } from "react-icons/fi";
import { RxExit, RxEnter } from "react-icons/rx";
import "./header.css";

export default function Header() {
  const { signed, user, logout } = useContext(AuthContext);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <div className="sidebar">
      <section className="view-logo">
        <h1 style={{ color: "#fff" }}>CLINICAWEB</h1>
      </section>
      <div className="links">
        {/* <Link to="/" className={isActive("/")}>
          <FiHome color="#FFfffF" size={24} />
          Inicio
        </Link> */}

        {/* {signed === true && (
          <Link to="/profile" className={isActive("/profile")}>
            <FiSettings color="#FfffFF" size={24} />
            Perfil
          </Link>
        )} */}
        {/* 
        {signed === true && (
          <Link to="/cadastros/profissionais" className={isActive("/cadastros/profissionais")}>
            <FiSettings color="#FfffFF" size={24} />
            Profissionais
          </Link>
        )} */}

        {signed === true && (
          <Link
            to="/cadastros/pacientes"
            className={isActive("/cadastros/pacientes")}
          >
            <FiSettings color="#FfffFF" size={24} />
            Pacientes
          </Link>
        )}

        {signed === true && (
          <Link to="/agenda" className={isActive("/agenda")}>
            <FiSettings color="#FfffFF" size={24} />
            Agenda
          </Link>
        )}
        {signed === true ? (
          <div className="div-nome-usuario">
            {/* <img
            src={user?.avatarUrl === null ? avatarImg : user?.avatarUrl}
            alt="Foto do usuario"
          /> */}
            <span>{user?.name}</span>
            <button onClick={() => logout()}>
              <RxExit color="#FFF" size={24} />
            </button>
          </div>
        ) : (
          <Link  to="/login" className={isActive("/login div-nome-usuario")}>
            <span>Login</span>
            <RxEnter color="#FFF" size={24} />
          </Link>
        )}
      </div>
    </div>
  );
}
