import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";

import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiPlus, FiMessageSquare, FiSearch, FiEdit2 } from "react-icons/fi";
import listProcess from "../../api/listProcess.json";
import { Link } from "react-router-dom";

import { format } from "date-fns";
import Modal from "../../components/Modal";

import "./home.css";

export default function Home() {
  return (
    <div style={{display: 'flex'}}>
      <Header />
      <Title name="Home">
        <FiMessageSquare size={25} />
      </Title>
      <div className="content">

      </div>
    </div>
  );
}
