import React, { useState } from "react";
import "./listaProfissionais.css";
import { IoMdAddCircle } from "react-icons/io";
import { FaPencilAlt, FaTrashAlt, FaRegEye } from "react-icons/fa";

const ProfissionaisLista = ({ profissionais }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (key) => {
    if (sortBy === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };

  const sortedProfissionais = profissionais.sort((a, b) => {
    if (sortBy && sortOrder === "asc") {
      return a[sortBy].localeCompare(b[sortBy]);
    } else if (sortBy && sortOrder === "desc") {
      return b[sortBy].localeCompare(a[sortBy]);
    }
    return 0;
  });

  return (
    <section className="content-lista-profissionais">
      <div>
        <h3>Profissionais da Clínica</h3>
        <button ><IoMdAddCircle /> Criar novo</button>
      </div>
      <table className="table-listagem-profissionais">
        <thead>
          <tr>
            <th onClick={() => handleSort("avatar")}>Avatar</th>
            <th onClick={() => handleSort("nome")}>Nome</th>
            <th onClick={() => handleSort("responsavel")}>Responsável</th>
            <th onClick={() => handleSort("cpf")}>CPF</th>
            <th onClick={() => handleSort("cidade")}>Cidade</th>
            <th onClick={() => handleSort("email")}>E-mail</th>
            <th onClick={() => handleSort("telefone")}>Telefone</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {sortedProfissionais.map((profissional, index) => (
            <tr key={index}>
              <td>{profissional.avatar}</td>
              <td>{profissional.nome}</td>
              <td>{profissional.responsavel}</td>
              <td>{profissional.cpf}</td>
              <td>{profissional.cidade}</td>
              <td>{profissional.email}</td>
              <td>{profissional.telefone}</td>
              <td>
                <button style={{ backgroundColor: "#d9e1e6" }}>
                  <FaRegEye />
                </button>

                <button style={{ backgroundColor: "#b3bbc2" }}>
                  <FaPencilAlt />
                </button>
                <button style={{ backgroundColor: "#f64846", color: "#fff" }}>
                  <FaTrashAlt />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default ProfissionaisLista;
