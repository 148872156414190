import React, { useState } from "react";
import "./listaGuias.css";
import { IoMdAddCircle } from "react-icons/io";
import { FaPencilAlt, FaTrashAlt, FaRegEye, FaBarcode, FaCalendarAlt  } from "react-icons/fa";

import { Link, useNavigate } from "react-router-dom";
import config from "../../config/variables.json";
import { toast } from "react-toastify";
import Modal from 'react-modal';
import ModalNovoAgendamento from "../../components/ModalNovoAgendamento";
import { formatCpf } from "../../functions/formatCPF";


const GuiasLista = ({ guias, user, getGuias, paciente }) => {
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();
  const [modalNovoAgendamentoIsOpen, setModalNovoAgendamentoIsOpen] = useState(false);
const [selectedGuia, setSelectedGuia] = useState(null)

  
  const openModalNovoAgendamento = (guia) => {
    setModalNovoAgendamentoIsOpen(true)
    setSelectedGuia(guia)
  }

  const closeModalNovoAgendamento = () => {
    setModalNovoAgendamentoIsOpen(false)
    setSelectedGuia(null)
    getGuias()

  }

  const handleSort = (key) => {
    if (sortBy === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };

  const sortedGuias = guias.sort((a, b) => {
    if (sortBy === 'quantidade_total' 
    ||sortBy ===  'quantidade_agendada'
    || sortBy === 'quantidade_executada_manual'
    || sortBy === 'quantidade_executada_robo'
    ) {
      const numA = parseInt(a[sortBy]);
      const numB = parseInt(b[sortBy]);
      return sortOrder === 'asc' ? numA - numB : numB - numA;
    } else {
      if (sortBy && sortOrder === "asc") {
        return a[sortBy].localeCompare(b[sortBy]);
      } else if (sortBy && sortOrder === "desc") {
        return b[sortBy].localeCompare(a[sortBy]);
      }
      return 0;
    }
  });
  

  const actionDelete = async (data) => {
    let { nome, cpf, _id } = data;
    const confirmarExclusao = window.confirm(`Tem certeza que deseja excluir a guia operadora?
    Dados da guia operadora:
      ${nome}
      CPF: ${cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
      
  Lembrando que, uma vez feita esta ação, não será possível reverter.
      `);

    if (_id && confirmarExclusao) {
      setLoading(true);
      const response = await fetch(
        `${config.urlServer}/cadastros/guias-operadoras/paciente/deletar/${_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            email: user.email,
            token: user.token,
          },
        }
      );
      if (!response.ok) {
        toast.error("Erro ao excluir guia operadora");
        throw new Error("Erro ao excluir guia operadora");
      }

      const guiaRemovida = await response.json();
      toast.success("Guia operadora excluída com sucesso!");
      setLoading(false);
      return getGuias();
    }
  };

  return (
    <section className="content-lista-guias">
      <div>
        <h3>Guias Operadoras</h3>
        <h2>{paciente?.nome}</h2>
        {/* <Link to="/cadastros/guias-operadoras/criar-nova"> */}
        <button
          onClick={() => {
            navigate("/cadastros/guias-operadoras/paciente/criar-nova", {
              state: { paciente },
            });
          }}
        >
          <IoMdAddCircle /> Criar nova
        </button>
        {/* </Link>{" "} */}
      </div>
      <table className="table-listagem-guias">
        <thead>
          <tr>
            <th onClick={() => handleSort("cpf")}>CPF</th>
            <th onClick={() => handleSort("carteira_unimed")}>
              Carteira Unimed
            </th>
            {/* <th onClick={() => handleSort("id_usuario")}>ID Usuário</th> */}
            <th onClick={() => handleSort("numero_guia_operadora")}>
              Número Guia Operadora
            </th>
            <th onClick={() => handleSort("descricao_guia")}>Descrição Guia</th>
        
            <th onClick={() => handleSort("quantidade_executada_manual")}>
              Qtd. Manual 
            </th>
            <th onClick={() => handleSort("quantidade_executada_robo")}>
              Qtd. Robô 
            </th>
            <th onClick={() => handleSort("quantidade_agendada")}>
              Qtd. Agendada
            </th>
            <th onClick={() => handleSort("quantidade_total")}>
              Qtd. Total
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {sortedGuias?.map((guia, index) => (
            <tr key={guia?._id}>
              <td>
                {formatCpf(guia?.cpf)}
              </td>
              <td>{guia?.carteira_unimed}</td>
              {/* <td>{guia?.id_usuario}</td> */}
              <td>{guia?.numero_guia_operadora}</td>
              <td>{guia?.descricao_guia}</td>
      
              <td>{guia?.quantidade_executada_manual}</td>
              
              <td>{guia?.quantidade_executada_robo}</td>
              <td>{guia?.quantidade_agendada}</td>
              <td>{guia?.quantidade_total}</td>
              <td>
              <button
                  style={{ backgroundColor: "#b3bbc2", }}
                  onClick={() =>
                    openModalNovoAgendamento(guia)
                  }
                >
              <FaCalendarAlt/> 
                </button>

                {/* <Link
                  to={`/cadastros/guias-operadoras/paciente/editar/${guia?._id}`}
                > */}
                <button
                  style={{ backgroundColor: "#b3bbc2" }}
                  onClick={() =>
                    navigate(
                      `/cadastros/guias-operadoras/paciente/editar/${guia?._id}`,
                      { state: { paciente } }
                    )
                  }
                >
                  <FaPencilAlt />
                </button>
                {/* </Link> */}

                <button
                  style={{ backgroundColor: "#f64846", color: "#fff" }}
                  onClick={() => actionDelete(guia)}
                >
                  <FaTrashAlt />
                </button>
              </td>
                    

            </tr>
            
          ))}
        </tbody>
      </table>
      <Modal
          isOpen={modalNovoAgendamentoIsOpen}
          onRequestClose={closeModalNovoAgendamento}
          style={customStylesRules}
        >
          <ModalNovoAgendamento
            user={user}
            closeModalNovoAgendamento={closeModalNovoAgendamento}
            paciente={paciente}
            selectedGuia={selectedGuia}
         />
        </Modal>

    </section>
  );
};

export default GuiasLista;


const customStylesRules = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%', // Defina a largura desejada
    // minWidth: '850px',
    // maxWidth: '1280px', // Defina uma largura máxima opcional
    height: '95vh', // Defina a altura desejada (exemplo: 60% da altura da viewport)
    maxHeight: '800px', // Defina uma altura máxima opcional
  }, overlay: {
    background: 'rgba(0, 0, 0, 0.3)', // Cor de fundo do overlay (fundo escuro semitransparente)
    zIndex: 1000, // Ajusta a ordem de empilhamento para garantir que o modal esteja acima do conteúdo
  },
};